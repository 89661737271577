import React, { useState, useEffect } from 'react';
import Form from './Form'
import Sede from './Sede';
import DatePickerComponent from './DatePickerComponent';
import TimeSelector from './TimeSelector';
import PatientForm from './PatientForm';
import ServiceAditional from './ServiceAditional';
import AditionalForm from './AditionalForm';
import axios from 'axios';



export default function Home() {
  const [professional, setProfessional] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [specialtyInperson, setSpecialtyInperson] = useState(null);
  const [countryInperson, setCountryInperson] = useState(null);
  const [departmentInperson, setDepartmentInperson] = useState(null);
  const [districtInperson, setDistrictInperson] = useState(null);
 
  const [sede, setSede] = React.useState([]);
 const Professionals = async () => {
  try {
    const res = await axios.get("https://vitalclic-production.up.railway.app/api/professionals");
    setProfessional(res.data.data)

  } catch (error) {
    console.log(error)
  }
 } 

 const allSede = async () => {
  try {
    const res = await axios.get("https://vitalclic-production.up.railway.app/api/all-sede");
    setSede(res.data.data)

  } catch (error) {
    console.log(error)
  }
 } 
 useEffect(() => {
  Professionals()
 }, []);
 useEffect(() => {
  allSede()
 }, []);
     
  return (
    <div>
   <Form specialty={specialty} setSpecialtyInperson ={setSpecialtyInperson} professional={professional} specialtyInperson= {specialtyInperson} setSpecialty={setSpecialty}/>
   <Sede sede ={sede}  specialtyInperson= {specialtyInperson}/>
   <DatePickerComponent  setSelectedDate={setSelectedDate} selectedDate={selectedDate} specialty={specialty}/>
   <TimeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime}selectedDate={selectedDate}/>
   <PatientForm specialty={specialty} selectedTime={selectedTime} selectedDate={selectedDate}/>
   <ServiceAditional/>
   <AditionalForm/>
    </div>
  );
}
