import RegisterUserComponent from '../components/RegisterUser/RegisterUser';
import Navbar from '../components/Navbar/Navbar';

export default function RegisterUser() {
    return (
        <div>
                        <Navbar/>

            <RegisterUserComponent/>
        </div>
    )
}