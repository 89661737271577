import LoginComponent from '../components/Login/Login'
import Navbar from '../components/Navbar/Navbar';

export default function Login() {
    return (
    <div>
            <Navbar/>

<LoginComponent/>
    </div>
    )
}