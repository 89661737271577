import Navbar from '../components/Navbar/Navbar';
import HomeComponent from '../components/Home/Home';

export default function Home() {
    return (
        <div>
            <Navbar/>
            <HomeComponent/>
        </div>
    )
}