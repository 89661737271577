import React from "react";
import styles from "../../styles/Home/Sede.module.css";
import { useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Avatar from "@mui/material/Avatar";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const Sede = ({
  specialtyInperson,
  countryInperson,
  departmentInperson,
  districtInperson,
  sede,
}) => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [open, setOpen] = React.useState(false);
  const [sedelId, setSedeId] = React.useState("");
  const [detailsSede, setDetailsSede] = React.useState(null);
console.log(detailsSede)
  const handleOpen = (sedeId) => {
    setSedeId(sedeId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const DetailsSede = async () => {
    try {
      const res = await axios.get(
        `https://vitalclic-production.up.railway.app/api/sede/${sedelId}`
      );
      setDetailsSede(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (sedelId) {
      DetailsSede();
    }
  }, [sedelId]);

  const handleSelect = (sede) => {
    localStorage.setItem("selectedSede", JSON.stringify(sede));
  };

  // Filtrar las sedes según country, department, y district
/*   const filteredSedes = sede.filter(
    (prof) =>
      prof.country === countryInperson &&
      prof.department === departmentInperson &&
      prof.district === districtInperson
  ); */

  return (
    <div id="sede" className={styles.container_sede}>
      {Array.isArray(sede) && sede.length > 0 ? (
        <>
          <h1>SELECCIONAR SEDE</h1>
          <div className={styles.hr}></div>
          <div className={styles.locations_list}>
            <div>
              {sede.length > 0 ? (
                sede.map((prof, index) => (
                  <div key={index} className={styles.location_card}>
                    <Avatar
                      src={prof && prof.image}
                      sx={{
                        backgroundColor: prof && prof.backgroundColor,
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    >
                      {prof && prof.image ? null : prof && prof.name[0]}
                    </Avatar>
                    <div className={styles.location_info}>
                      <h2>
                     {prof.name}
                      </h2>
                      <p>{prof.specialty}</p>
                      <p> {prof.address}</p>
                      <span
                        className={styles.link}
                        onClick={() => handleOpen(prof.id)}
                      >
                        Ver Más información
                      </span>
                      <br />
                      <span
                        className={styles.link}
                        onClick={() => handleSelect(prof)}
                      >
                        Seleccionar
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p>No se encontraron resultados para la selección actual.</p>
              )}
            </div>
            <Modal
  aria-labelledby="transition-modal-title"
  aria-describedby="transition-modal-description"
  open={open}
  onClose={handleClose}
  closeAfterTransition
  slots={{ backdrop: Backdrop }}
  slotProps={{
    backdrop: {
      timeout: 500,
    },
  }}
>
  <Fade in={open}>
    <Box sx={style}>
      {detailsSede ? (
        <div key={detailsSede.id} className={styles.profile}>
          <Avatar
            src={detailsSede.image}
            sx={{
              backgroundColor: detailsSede.backgroundColor,
              width: "200px",
              height: "200px",
              marginBottom: "15px",
              border: "4px solid #f0f0f0",
              transition: "borderColor 0.3s",
              borderRadius: "10px",
              objectFit: "cover",
              margin: "auto",
            }}
          >
            {detailsSede.image ? null : detailsSede.name[0]}
          </Avatar>
          <h2 className={styles.name}>
            {detailsSede.name}
          </h2>
          <p className={styles.specialty}>
            Especialidad: {detailsSede.specialty || "No especificada"}
          </p>
          <p>
            RNE: {detailsSede.specialty_number_rne || "No disponible"}
          </p>
          <p>
            Correo: {detailsSede.email}
          </p>
          <p>
            Teléfono: {detailsSede.phone}
          </p>
          <p>
            Dirección: {detailsSede.address}
          </p>
          <p>
            Provincia: {detailsSede.province}
          </p>
          <p>
            Distrito: {detailsSede.district}
          </p>
        </div>
      ) : (
        <p>No hay información disponible.</p>
      )}
    </Box>
  </Fade>
</Modal>

          </div>
        </>
      ) : (
        <div>
       
        </div>
      )}
    </div>
  );
};

export default Sede;
